<template>
  <storePay pageType="qc-punish"/>
</template>
<script>
import storePay from '../store-pay'
export default {
  components: {
    storePay
  }
}
</script>
<style>

</style>
